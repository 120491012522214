
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MTextArea from "@/components/form/MTextArea.vue";
import { Student } from "@/entities/student";
import store from "@/store";
import { Options, Vue } from "vue-class-component";
import {
  sendImageMessageToStudents,
  sendTextMessageToStudents
} from "@/api/message";
import { uploadImage } from "@/api/storage";
import { saveErrorLog } from "@/api/error";

@Options({
  components: {
    MBaseModal,
    MButton,
    MIcon,
    MTextArea
  },
  emits: ["close"],
  props: {
    students: Array
  }
})
export default class MrSendModal extends Vue {
  students: Student[] = [];
  message = "";
  files: File[] = [];

  get fileUrls(): string[] {
    return this.files.map(file => URL.createObjectURL(file));
  }

  get validMessage() {
    return this.message.length > 0;
  }

  get validData() {
    return this.validMessage && this.students.length > 0;
  }

  get filteredStudents(): Student[] {
    return this.students.filter(
      student => !student.data.recessTime || student.data.recessTime <= 0
    );
  }

  imageUpload(event: Event) {
    if (!(event.target instanceof HTMLInputElement)) {
      return;
    }
    if (!event.target.files || event.target.files.length === 0) {
      return;
    }

    let index = 0;
    while (index < event.target.files.length) {
      if (this.files.length == 3) {
        alert("同時に送信できる画像は3個までです");
        break;
      }
      const item = event.target.files.item(index);
      if (item) {
        if (item.size > 750000) {
          alert("750KB以上のファイルサイズの画像は送信できません");
        } else {
          this.files.push(item);
        }
      }
      index++;
    }
  }

  removeImage(index: number) {
    const file = this.files[index];
    this.files = this.files.filter(item => item.name !== file.name);
  }

  async sendMessage() {
    const senderName =
      store.state.admin?.name || store.state.tutor?.main.data.name;
    if (!this.validData || !store.state.role || !senderName) {
      return;
    }

    store.commit("START_LOADING", "メッセージ送信中...");

    const messageText = this.message;
    this.message = "";
    try {
      await sendTextMessageToStudents(
        this.filteredStudents.map(student => student.ref),
        "",
        {
          type: store.state.role.data.type,
          userId: store.state.role.ref.id,
          name: senderName
        },
        messageText
      );
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      this.message = messageText;
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to send text message"
      );
      return;
    }

    try {
      const imagePathes = await Promise.all(
        this.files.map(file => uploadImage(file, this.students[0].ref.id))
      );
      await Promise.all(
        imagePathes.map(path =>
          sendImageMessageToStudents(
            this.filteredStudents.map(student => student.ref),
            {
              type: store.state.role!.data.type,
              userId: store.state.role!.ref.id,
              name: senderName
            },
            path
          )
        )
      );
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to send image message"
      );
      return;
    }

    this.files = [];

    store.commit("END_LOADING");

    alert("メッセージの一斉送信が完了しました");
    this.$router.go(0);
  }

  close() {
    this.$emit("close");
  }
}
