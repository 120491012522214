
import { Options, Vue } from "vue-class-component";
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MNavBar from "@/components/MNavBar.vue";
import MHeaderPanel, { TabItem } from "@/components/MHeaderPanel.vue";
import MRoomPanel from "@/components/room/MRoomPanel.vue";
import MREditEndTimeModal from "@/components/room/MREditEndTimeModal.vue";
import MRJoinModal from "@/components/room/MRJoinModal.vue";
import MrAddModal from "@/components/room/MrAddModal.vue";
import MrSendModal from "@/components/room/MrSendModal.vue";
import { Student, StudentSecret } from "@/entities/student";
import { Room } from "@/entities/room";
import { School } from "@/entities/school";
import { Group } from "@/entities/group";
import { Classroom } from "@/entities/classroom";
import { Registrant } from "@/entities/registrant";
import { Target } from "@/entities/target";
import { Getter, State } from "@/store/helper";
import store from "@/store";
import { getCurrentUnixtime } from "@/utils/date";
import { saveErrorLog } from "@/api/error";
import { deleteRoom } from "@/api/room";

@Options({
  components: {
    MButton,
    MIcon,
    MNavBar,
    MHeaderPanel,
    MRoomPanel,
    MREditEndTimeModal,
    MRJoinModal,
    MrAddModal,
    MrSendModal
  }
})
export default class RoomSettings extends Vue {
  tabs: TabItem[] = [
    {
      text: "開室中",
      link: "/room"
    },
    {
      text: "今後の予定",
      link: "/room/future"
    },
    {
      text: "入室中生徒",
      link: "/room/student"
    }
  ];
  currentTabIndex = 0;
  selectedRoomId = "";
  startUrl = "";
  isDisplayedAddRoomModal = false;
  isDisplayJoinModal = false;
  isDisplayEditEndTimeModal = false;
  isDisplaySendModal = false;
  endTime = 0;

  @State("rooms", "room") rooms!: Room[];
  @Getter("learningRegistrants", "room") registrants!: Registrant[];

  @State("school") school!: School | null;
  @State("schools") schools!: School[];
  @State("classrooms") classrooms!: Classroom[];
  @State("students") students!: Student[];
  @State("studentSecrets") studentSecrets!: StudentSecret[];
  @State("groups") groups!: Group[];
  @State("targets") targets!: Target[];
  @Getter("isAdmin") isAdmin!: boolean;
  @Getter("isTutorOfSchool") isTutorOfSchool!: boolean;
  @Getter("allowCreateRoom") allowCreateRoom!: boolean;

  get selectedRoom(): Room | null {
    const matchRooms = this.rooms.filter(
      room => room.ref.id === this.selectedRoomId
    );
    if (matchRooms.length > 0) {
      return matchRooms[0];
    } else {
      return null;
    }
  }

  get studentsOfSelectedRoom(): Student[] {
    const registrantsOfRoom = this.registrants.filter(
      item => item.ref.parent.parent!.id === this.selectedRoomId
    );
    return this.students.filter(student =>
      registrantsOfRoom
        .map(item => item.data.student.id)
        .includes(student.ref.id)
    );
  }

  registrantsOfRoom(roomId: string): Registrant[] {
    return this.registrants.filter(
      item => item.ref.parent.parent!.id === roomId
    );
  }

  showSendModal(roomId: string) {
    this.selectedRoomId = roomId;
    this.isDisplaySendModal = true;
  }

  async deleteRoom(meetingId: string, roomName: string) {
    const confirmRes = confirm(
      `${roomName}を強制終了しますが、よろしいですか？`
    );
    if (!confirmRes) {
      return;
    }

    store.commit("START_LOADING", `${roomName}を強制終了中...`);
    try {
      await deleteRoom(
        meetingId,
        getCurrentUnixtime(),
        this.allowCreateRoom || this.isAdmin || this.isTutorOfSchool
      );
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to delete room"
      );
      return;
    }
    store.commit("END_LOADING");
    alert(`${roomName}を強制終了しました`);
    this.$router.go(0);
  }

  showEditEndTimeModal(roomId: string, end: number) {
    this.selectedRoomId = roomId;
    this.isDisplayEditEndTimeModal = true;
    this.endTime = end;
  }

  joinRoom(roomId: string) {
    const matchRooms = this.rooms.filter(room => room.ref.id === roomId);
    if (matchRooms.length === 0) {
      return;
    }

    this.startUrl = matchRooms[0].data.joinUrl;
    this.isDisplayJoinModal = true;
  }

  hostJoinRoom(roomId: string) {
    const matchRooms = this.rooms.filter(room => room.ref.id === roomId);
    if (matchRooms.length === 0) {
      return;
    }

    this.startUrl = matchRooms[0].data.hostUrl;
    this.isDisplayJoinModal = true;
  }

  created() {
    store.dispatch("room/setup");
  }
}
