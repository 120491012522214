
import MBaseModal from "@/components/MBaseModal.vue";
import MIcon from "@/components/MIcon.vue";
import QrcodeVue from "qrcode.vue";
import { Options, Vue } from "vue-class-component";

@Options({
  components: {
    MBaseModal,
    MIcon,
    QrcodeVue
  },
  emits: ["close"],
  props: {
    url: String
  }
})
export default class MRJoinModal extends Vue {
  url = "";
  isDisplayMeetingId = false;

  get meetingId(): string {
    const dividedUrlTexts = this.url.split("?")[0].split("/");
    if (dividedUrlTexts.length <= 1) {
      return "";
    }
    return dividedUrlTexts[dividedUrlTexts.length - 1];
  }

  showMeetingId() {
    this.isDisplayMeetingId = true;
  }

  close() {
    this.$emit("close");
  }
}
