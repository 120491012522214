
import { updateEndTimeOfRoom } from "@/api/room";
import MBaseModal from "@/components/MBaseModal.vue";
import MButton from "@/components/MButton.vue";
import MTextField from "@/components/form/MTextField.vue";
import store from "@/store";
import dayjs from "dayjs";
import { Options, Vue } from "vue-class-component";
import { saveErrorLog } from "@/api/error";
import {
  convertToDateFromUnixtime,
  convertToTimeFromUnixtime,
  getCurrentUnixtime
} from "@/utils/date";

function convertToUnixtime(date: string, time: string): number {
  return dayjs(`${date} ${time}`).locale("ja").unix();
}

@Options({
  components: {
    MBaseModal,
    MButton,
    MTextField
  },
  emits: ["close"],
  props: {
    meetingId: String,
    endTime: Number
  }
})
export default class MREditEndTimeModal extends Vue {
  meetingId = "";
  date = "";
  time = "";
  endTime = 0;

  get validDate(): boolean {
    return /^\d{4}-\d{2}-\d{2}$/.test(this.date);
  }

  get validTime(): boolean {
    return /^\d{2}:\d{2}$/.test(this.time);
  }

  get validData(): boolean {
    return (
      (this.validDate && this.validTime) || (!this.validDate && !this.validTime)
    );
  }

  async updateEndTimeOfRoom() {
    if (!this.validData) {
      return;
    }

    const end =
      this.date && this.time ? convertToUnixtime(this.date, this.time) : null;
    if (!!end && end < getCurrentUnixtime()) {
      confirm("閉室予定日時は現在より後の日時を設定してください。");
      this.date = "";
      this.time = "";
      return;
    }

    store.commit("START_LOADING", "閉室予定時刻を更新中...");

    try {
      await updateEndTimeOfRoom(this.meetingId, end);
    } catch (e) {
      store.commit("END_LOADING");
      alert(e);
      await saveErrorLog(
        store.state.role,
        e.code,
        e.message,
        "Failed to update end of room"
      );
      return;
    }
    store.commit("END_LOADING");

    alert("閉室予定時刻を更新しました");
    this.$router.go(0);
  }

  created() {
    if (!this.endTime) {
      const dayData = dayjs().locale("ja").add(1, "h");
      this.date = dayData.format("YYYY-MM-DD");
      this.time = dayData.format("HH:mm");
    } else {
      this.date = convertToDateFromUnixtime(this.endTime);
      this.time = convertToTimeFromUnixtime(this.endTime);
    }
  }

  close() {
    this.$emit("close");
  }
}
