
import MButton from "@/components/MButton.vue";
import MIcon from "@/components/MIcon.vue";
import MTable from "@/components/MTable.vue";
import { Classroom } from "@/entities/classroom";
import { Registrant } from "@/entities/registrant";
import { Room } from "@/entities/room";
import { School } from "@/entities/school";
import { Student, StudentSecret } from "@/entities/student";
import store from "@/store";
import {
  convertToRegistrantTableData,
  registrantsOfRoomAdminTableDefinition,
  registrantsOfRoomTutorTableDefinition,
  TableDefinition
} from "@/utils/table";
import { Options, Vue } from "vue-class-component";
import { Getter } from "@/store/helper";
import {
  convertToDateFromUnixtime,
  convertToTimeFromUnixtime
} from "@/utils/date";

@Options({
  components: {
    MButton,
    MIcon,
    MTable
  },
  emits: ["deleteRoom", "join", "joinHost", "openSendModal", "editEndTime"],
  props: {
    room: Object,
    school: Object,
    isAdmin: Boolean,
    registrants: Array,
    students: Array,
    studentSecrets: Array,
    schools: Array,
    classrooms: Array
  }
})
export default class MRoomPanel extends Vue {
  room!: Room;
  isAdmin = false;
  registrants: Registrant[] = [];
  students: Student[] = [];
  studentSecrets: StudentSecret[] = [];
  schools: School[] = [];
  classrooms: Classroom[] = [];
  isDisplayedStudents = false;
  @Getter("allowCreateRoom") allowCreateRoom!: boolean;

  get definitions(): TableDefinition[] {
    return this.isAdmin
      ? registrantsOfRoomAdminTableDefinition
      : registrantsOfRoomTutorTableDefinition;
  }

  get isOwnerOfRoom(): boolean {
    if (!store.state.role) {
      return false;
    }
    if (!this.room.data.school) {
      return this.isAdmin;
    } else if (!store.state.school) {
      return false;
    } else {
      return (
        store.state.school.ref.id === this.room.data.school.id &&
        store.state.school.data.admins.includes(store.state.role.ref.id)
      );
    }
  }

  get isTutorOfRoom(): boolean {
    if (!store.state.role) {
      return false;
    }
    if (!this.room.data.school) {
      return this.isAdmin;
    } else if (!store.state.school) {
      return false;
    } else {
      return (
        store.state.school.ref.id === this.room.data.school.id &&
        store.state.school.data.tutors.includes(store.state.role.ref.id)
      );
    }
  }

  get acceccableRegistrants() {
    return this.registrants.filter(item =>
      this.students
        .map(student => student.ref.id)
        .includes(item.data.student.id)
    );
  }

  get datas(): { [key: string]: string | number | boolean }[] {
    return this.acceccableRegistrants.map(item =>
      convertToRegistrantTableData(
        item,
        this.students,
        this.studentSecrets,
        [],
        this.schools,
        this.classrooms
      )
    );
  }

  get endTime(): string {
    return this.room.data.end
      ? `${convertToDateFromUnixtime(
          this.room.data.end
        )} ${convertToTimeFromUnixtime(this.room.data.end)}`
      : "未設定";
  }

  changeAccordionStatus() {
    this.isDisplayedStudents = !this.isDisplayedStudents;
  }

  deleteRoom() {
    if (!(this.isAdmin || this.allowCreateRoom || this.isTutorOfRoom)) {
      return;
    }

    this.$emit("deleteRoom");
  }

  editEndTime() {
    if (!(this.isAdmin || this.allowCreateRoom || this.isTutorOfRoom)) {
      return;
    }

    this.$emit("editEndTime");
  }

  joinRoom() {
    this.$emit("join");
  }

  joinHost() {
    this.$emit("joinHost");
  }

  openSendModal() {
    this.$emit("openSendModal");
  }
}
